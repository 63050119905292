import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Blog = () => {
  const data = useStaticQuery(graphql`
    query {
      BannerImage: file(relativePath: { eq: "product_banner.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Blog Page" />
      <div className="container">
        <div>
          <Img fluid={data.BannerImage.childImageSharp.fluid} />
        </div>
        <div>
          <p className="blog-content">
            <b>The Significance of Lighting Candles in the Church</b>
          </p>
          <p className="blog-content">
            The symbolism of Light and Fire in Christianity is profound. They
            represent the divine presence and nature. Christ is considered the
            Light, and every Christian in the child of Light warring and
            triumphing over the forces of darkness. Light depicts the purifying
            and the redeeming spirit of God
          </p>
          <p className="blog-content">
            The Praxis of lighting candles remains a crucial practice in both
            Catholic and Orthodox churches and families. Old Testament
            emphasized the sacredness of this divine Light to "sustain a
            perpetual flame" (Exodus 27:19-20). The New Testament, similarly.
            stresses the purity of the Light in Paul's Letter to the Hebrews
            that states. "A first tent was prepared with the lampstand, the
            table and the bread of the Presence, this is called the Holy Place"
            (Hebreus 9:2) Hence, the Light has a special significance, or rather
            the custom of lighting candles holds a special place in
            Christianity. It symbolizes Jesus Christ, who affirmed our faith and
            guided us, saying "I am the Light of the world; the one who follows
            me will not walk in darkness but will have light and life" (John
            8:12).
          </p>
          <p className="blog-content">
            <b>Why Christians light candles?</b>
          </p>
          <p className="blog-content">
            Almost all aspects of Catholic celebrations and liturgies highlight
            the significant custom of lighting candles. Candles are, therefore,
            lit during the Mass, at a funeral, and liturgical processions as
            well as evening prayers. Candles are lighted in front of the
            Tabernacle. It symbolizes the presence of the Lond in the Blessed
            Sacrament as they call for devotion and piety on the faithful.
          </p>
          <p className="blog-content">
            In the Sacrament of Baptisms, a candle is lighted from the Paschal
            Candle when an individual is initiated into the Church. The Paschal
            Candle represents Christ's Paschal Mystery - His resurrection. His
            death, and His passion. The person accepts the Light of Christ to
            live and traverse the path of faith following the footsteps of God's
            children. The light is the flame of trust and faith burning within
            the heart of the faithful. This indelible and infallible baptismal
            mark is the first step towards living a life of faith. And when the
            time comes, he shall meet the Supreme and be one with the saints in
            Heaven. The guiding light will help the faithful stay on course to
            attain his divinity within and meet the Lord at the end of his
            journey Light is the manifestation of God.
          </p>
          <p className="blog-content">
            The burning of candles signifies the power of light and
            illamination. It irradiates the darkness of life, which more
            precisely indicates individual life. It epitomizes the holy
            illumination of the essence of truth. Light is also associated with
            the tasks of cleaning and purification. The light of truth and faith
            will guide in this life and the next. Christ is the light of
            divinity. It is believed that our humility, obedience, and humanity
            will take us into the realms of godliness. The Light transform us
            from within and helps transform the atmosphere around us.
          </p>
          <p className="blog-content">
            Candles with all their fervent glimmer are lighted before the Lord
            and the saints. The practice not only honors but celebrates what the
            images truly represent. The Light embodies our prayer offerings. We
            seek the Lond's guidance and beseech the saints to pray for and with
            us during our times of crisis. This burning light demoustrates our
            desire to be on the path of God as we attempt to complete our daily
            responsibilities in our personal and professional lives.
          </p>
          <p className="blog-content">
            The Light symbolites our reverence for Christ who deserves
            thanksgiving and adoration as only He can forgive all sins to
            enlighten us joto a more trusting relationship with Him. The burning
            light at the altar is all about imploring the Lord, the Source of
            all Light, to be ever-present in your life to strengthen, instruct,
            inspire and guide us His ever-shining light will dispel the shadows
            of darkness and doubt. No celebration at the church is complete
            without the presence of liturgical candles.
          </p>
          <p className="blog-content">
            <b>From votive, alter candles to modem LED candles</b>
          </p>
          <p className="blog-content">
            Votive dies lighted as votive offerings are an integral part of
            prayer. These candles are used to continue the prayers as they are
            burned over extended periods to prolong and amplify your prayers
            along with other believers' intentions. This beautiful tradition
            establishes a collective spirit of worship and faith in the clues
            which is enriching, empowering and encouraging. Your prayer
            intentions will be carried forward to the universe with the burning
            light. A wide variety of votive candles are available think: simple
            church codles, Paschal candles, Advent candles, and more.
          </p>
          <p className="blog-content">
            Altar candies are votive or longer-lasting, taller cauilles and may
            come in glass containers. Earlier altar candles were made of
            beeswax. However, modem usage includes paraffin as well as say wax
            These candles embody the Light of God that illuminates the world
            resisting darkness and pain.
          </p>
          <p className="blog-content">
            Electric and LED candles de not take away from the sacredness and
            solemnity of our sacrifice. A stunningly designed LED candle is
            equally evocative as a traditional candle and has numerous pros in
            terms of cleanliness and safety LED prayer candles make a safer
            choice for burning for extended hours without any risk. These
            canilles are also an environmentally responsibile choice for a
            conscions individual. The diversity of shapes, sizes, and designs
            make LED candles quite popular choice since their introduction. You
            can also customize the message along with the candle.
          </p>
          <p className="blog-content">
            <b>Light Safe Candle: Your Exclusive Service Provider</b>
          </p>
          <p className="blog-content">
            This connotation of purity and faith has been understood and
            beautifully expressed by Light Safe Candle. They have been bridging
            the gap between the community and churches while addressing safery
            concerns and cost-effectiveness. Light Safe Candle pro vides a
            unique solution that helps churches efficiently deal with the
            vulnerability of open-fire candle stations by providing low voltage,
            completely safe, bold LED candles.
          </p>
          <p className="blog-content">
            The organization has come up with patented software and hardware.
            This software will allow someone to light candle in sy preferred
            church directly from her smartphone and/or browser. The platform
            also provides the alternative of displaying desired photos and texts
            in the church. Light Safe Candle supports churches and believers by
            keeping them safe and lowering insurance fees as open fire threats
            get eliminated. It offers 24/7 technical support fie a reusable
            candle station sa Maintenance Free Light Safe Candle service.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
